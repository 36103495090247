<template>
  <div class="section-learning" id="learning">
    <div class="container">
      <ui-title class="section-learning__title" text="Обучение" line/>
      <div class="section-learning__content-wrap">    
        <div class="section-learning__info">
          <ui-title class="section-learning__info-title" text="Нагрузочное тестирование с нуля"/>
          <div class="section-learning__desc">Длительность : 18 недель</div>
          <div class="section-learning__desc">Уровень : с нуля до junior </div>
          <div class="section-learning__desc">После прохождения курса у Вас будут навыки уровня junior, они дают возможность устроиться в нашу компанию или самостоятельно выйти на рынок труда</div>
          <div class="section-learning__desc">Подробнее на сайте школы <a href="https://xset.school">XSET.school</a></div>
          <!--<div class="section-learning__desc">запасной текст</div> -->
          <ui-title class="section-learning__info-title" text="Кому подходит курс"/>
          <div class="section-learning__desc">Курс подходит людям с техническим бэкграундом, которые хотят развиваться в сфере IT</div>
          <ui-button text="Записаться на курсы НТ" @click="scrollToSlide('contact')"/>
        </div>
        <img class="section-learning__image" src="@/assets/images/Learning.jpg" />
      </div>
    </div>
  </div>
</template>

<script>
import UiTitle from '@/components/ui/UiTitle'
import UiButton from '@/components/ui/UiButton'

export default {
  components: {
    UiTitle,
    UiButton,
  },
  methods: {
    scrollToSlide(id) {
      this.$emit('scrollToSlide', id)
    },
  },
}
</script>

<style lang="scss">
@import "../../assets/scss/mixins.scss";

.section-learning {
  display: flex;
  justify-content: center;
  padding: 40px 0;

  &__title {
    margin-bottom: 24px;
  }

  &__content-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    min-height: fit-content;
    gap: 11%;
    margin-bottom: 64px;

    @include breakpoint(desktop) {
      gap: 20px;
    }

    @include breakpoint(phablet) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &-title {
      @include breakpoint(phablet) {
        width: 100%;
        text-align: center;
        justify-content: center;
        white-space: initial;
      }
    }

    @include breakpoint(phablet) {
      align-items: center;
    }
  }
  
  &__desc {
    margin-bottom: 32px;

    @include breakpoint(phablet) {
      text-align: center;
    }
  }

  &__image {
    @include breakpoint(laptop) {
      width: 350px;
      height: 280px;
    }

    @include breakpoint(tablet) {
      width: 300px;
      height: 200px;
    }
  }
}
</style>
